import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu } from "react-icons/md";
import { MdClose } from "react-icons/md";
import logo from '../images/gatewaylogo.png';

const Header = () => {

    const [click, setClick] = useState(false);
    const openMenu = () => setClick(!click);
    const closeMenu = () => setClick(false);

    return (
        <header>
            <MdMenu className={click ? "mobile-touch slide-hamburger" : "mobile-touch"} onClick={openMenu} />
            <MdClose className={click ? "mobile-touch black" : "mobile-touch black slide-hamburger"} onClick={closeMenu} />
            <nav className="dark-blue-bg" id="main-nav">
                <div className="container-narrow">
                    <div id="header-logo"><Link to='/'><img src={logo} alt="logo" /></Link></div>
                    <div id="nav-links" className={click ? "slide-nav" : ""}>
                        <li className="has-dropdown"><Link to="/what-we-believe" onClick={closeMenu}>ABOUT US</Link>
                            <ul className="dropdown">
                                <li><Link to='/what-we-believe' onClick={closeMenu}>What We Believe</Link></li>
                                <li><Link to='/our-pastor' onClick={closeMenu}>Our Pastor</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/messages" onClick={closeMenu}>LISTEN</Link></li>
                        <li className="has-dropdown"><Link to='/live' onClick={closeMenu}>WATCH</Link>
                            <ul className="dropdown">
                                <li><Link to='/live' onClick={closeMenu}>Gateway Live</Link></li>
                                <li><Link to='/video' onClick={closeMenu}>Sunday Services</Link></li>
                            </ul>
                        </li>
                        <li className="has-dropdown"><Link to="/local-ministries" onClick={closeMenu}>MINISTRIES</Link>
                            <ul className="dropdown">
                                <li><Link to='/jubilee' onClick={closeMenu}>Winter Jubilee</Link></li>
                                <li><Link to='/local-ministries' onClick={closeMenu}>Local Ministries</Link></li>
                                <li><Link to='/missions' onClick={closeMenu}>Missionaries</Link></li>
                                <li><Link to='/in-the-word' onClick={closeMenu}>In The Word</Link></li>
                            </ul>
                        </li>
                        <li><Link to='/contact' className="nav-link" onClick={closeMenu}>CONTACT US</Link></li>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;