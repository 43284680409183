import React from 'react';
import { Link } from 'react-router-dom';
import youtubeIcon from '../images/youtube-icon.png';
import facebookIcon from '../images/facebook-icon.png';
import instagramIcon from '../images/instagram-icon.png';

const Footer = () => {
    return (
        <>
            <footer className="dark-blue-bg">
                <div className="container-narrow  padding-50-20">
                    <div className="footer-menu">
                        <div className="menu-group">
                            <h3>About Us</h3>
                            <li><Link to="/contact">Service Times</Link></li>
                            <li><Link to="what-we-believe">What We Believe</Link></li>
                            <li><Link to="/our-pastor">Our Pastor</Link></li>
                        </div>

                        <div className="menu-group">
                            <h3>Media</h3>
                            <li><Link to="/messages">Messages</Link></li>
                            <li><Link to="/live">Gateway Live</Link></li>
                            <li><Link to="/jubilee">Winter Jubilee</Link></li>
                        </div>

                        <div className="menu-group">
                            <h3>Ministries</h3>
                            <li><Link to="/local-ministries">Local Ministries</Link></li>
                            <li><Link to="/missions">Missionaries</Link></li>
                            <li><Link to="/in-the-word">In The Word</Link></li>
                        </div>
                    </div>

                    <div id="social">
                        <a href="https://www.youtube.com/@gbcsprings" target="_blank" rel="noreferrer"><img src={youtubeIcon} alt="youtube" /></a>
                        <a href="https://www.facebook.com/gbcsprings" target="_blank" rel="noreferrer"><img src={facebookIcon} alt="facebook" /></a>
                        <a href="https://www.instagram.com/gbcsprings/" target="_blank" rel="noreferrer"><img src={instagramIcon} alt="instagram" /></a>
                    </div>

                    <h4 className="white center-text">Gateway Baptist Church</h4><br/>
                    <div className="white center-text margin-top-10">
                        <p className="white tight"><b>STREET ADDRESS:</b><br/>1600 Old Furnace Road, Boiling Springs, SC  29316</p><br/>
                        <p className="white tight"><b>MAILING ADDRESS:</b><br/>P.O. Box 160086, Boiling Springs, SC  29316</p><br/>
                        <p className="white"><a href="mailto:office@gatewayjubilee.com">Email us</a> or Call: <a href="tel:8645781677" className="white">864-578-1677</a></p>
                    </div>

                </div>
            </footer>
        </>
    );
}

export default Footer;
