import React, { useEffect } from 'react';

const Pastor = () => {
    useEffect(() => {
        topFunction();
    }, []);

    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        <>
            <div id="dark-blue-bg">
                <div className="container page-hero" id="pastor-hero">
                    <div className="page-hero-message">
                        <h1>Our Pastor</h1>
                    </div>
                </div>
            </div>

            <section className="light-bg">
                <div className="container-narrow padding-50-20">

                    <div className="max-width-1000 center margin-bottom-20">
                        <div>
                            <p className="padding-side-20 border-left">"I count it a privilege to be the Pastor of Gateway Baptist Church. The Lord has truly blessed us beyond measure here at Gateway. My prayer is that God will keep HIS hand upon us and continue to add to our church family. May we continue to pray for each other. As we are living in the last days, the need for a church family is greater than ever."</p>
                            <p className="right-text"><b>&mdash;Pastor Davy Shelton</b></p>
                        </div>
                    </div>

                    <div>
                        <p className="padding-25-20-100 small-text">Pastor Shelton was born Dec. 8, 1969 in Asheville, N.C. He was raised in Swannanoa a little town East of Asheville. Pastor Shelton was saved, baptized, and joined Swannanoa Heights Missionary Baptist Church in the spring of 1980. The Lord called him to Preach at an Old-Fashioned Tent Meeting in May 1985. He attended Trinity Baptist Bible College in Asheville, N.C.<br /><br />
                        Pastor Shelton met a young lady, Renee Presley, at the Land of the Sky Jubilee at Trinity Baptist church in July 1990 and they were married March 27, 1993. They have four children Katelyn, Brittany, Elijah, and Chloe, one Son-in-law Nathan Parker (married to Brittany), and one Granddaughter Abbey Grace Parker.<br /><br />
                        Pastor Shelton pastored three Churches from Jan. 1991 – Oct. 2010 when God called him to be a full time Evangelist. The first Revival he preached was at Gateway Baptist Church in Boiling Springs, S.C. in Oct. 2010. Little did he know what God had in store at the time and that Gateway Baptist would call him to be their Pastor 5 years later in June 2015.<br /><br />
                        Pastor Shelton has been preaching on the Radio and TV, and in numerous Nursing homes, Churches, Camp Meetings, Jubilees, Tent Meetings and in two foreign Countries (Kenya and Uganda) which have ranged from 10 to over 1400 in the congregations.<br /><br />
                        He is also the Moderator of the Allamance County Baptist Campmeeting and a Board Member of Prayer Baptist Missions.</p>
                    </div>

                    {/* <div className="flex flex-center max-width-1000 center">
                        <img className="width-33 margin-bottom-20-s" src="https://via.placeholder.com/150" alt="pastor" />
                        <div className="width-66 flex flex-center flex-column">
                            <div>
                                <p className="padding-side-20 center">"I count it a privilege to be the Pastor of Gateway Baptist Church. The Lord has truly blessed us beyond measure here at Gateway. My prayer is that God will keep HIS hand upon us and continue to add to our church family. May we continue to pray for each other. As we are living in the last days, the need for a church family is greater than ever."</p>
                                <p className="right-text"><b>&mdash;Pastor Davy Shelton</b></p>
                            </div>
                        </div>
                    </div> */}





                </div>
            </section>
        </>
    );
}

export default Pastor;
